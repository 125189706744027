import {getCountryFromLocale, getLanguageFromLocale as getLocaleWithDefault} from '@wix/wix-events-commons-statics'
import {IControllerConfig} from '@wix/yoshi-flow-editor'
import {CommonState} from '../types/state'

const MAX_FULL_WIDTH_MARGIN = 106

export const getLocale = (state: CommonState) => getLocaleWithDefault(state.environment.locale)

export const getCountry = (state: CommonState) => getCountryFromLocale(state.environment.locale)

export const getLanguage = (state: CommonState) => state.environment.language

export const isMobile = (state: CommonState) => state.environment.mobile

export const isEditor = (state: CommonState) => state.environment.editor

export const isResponsiveEditor = (config: IControllerConfig) => config?.style?.styleParams?.booleans?.responsive

export const isSite = (state: CommonState) => state.environment.site

export const isPreview = (state: CommonState) => state.environment.preview

export const isFullWidth = (state: CommonState, dimensions: {width: number; left: number}) => {
  if (isMobile(state)) {
    return false
  }

  return (
    String(dimensions.width) === '' || String(dimensions.width) === '100%' || dimensions.left < MAX_FULL_WIDTH_MARGIN
  )
}
